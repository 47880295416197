import React from "react";

const AlertCmponent = () =>{
    return(
        <section className="w-full flex flex-col  relative lg:px-0 py-10 lg:py-0 lg:max-h-[220px] bg-[#1F2A62] bg-cover lg:bg-no-repeat">
            {/* <div className="flex flex-col gap-4 lg:gap-0  relative lg:flex-row lg:py-[20px] "> */}
            <div className="lg:px-[68px] px-10">
            <div className="justify-center" style={{color:'white', justifyContent:'center', padding:'15px', fontSize:'15px'}}>
            <b>
            <sapn>
            As per amendment in section 200(3) of the Income-tax Act vide FINANCE (No.2) ACT,2024, no correction statement shall be delivered after the expiry of six years from the end of the financial year in which the statement referred to in sub-section (3) is required to be delivered. In view of the above, correction statements pertaining to Financial Year 2007-08 to 2018-19 shall be accepted only up-to 31st March 2025. Deductors/Collectors and other Stakeholders may kindly take note.
            </sapn>
            <br></br>
            <br></br>
            <span>
            वित्त (संख्या 2) अधिनियम, 2024 के माध्यम से आयकरअधिनियम की धारा 200(3) मे संशोधनके अनुसार, वित्तीय वर्ष के अंतसे छह साल की समाप्ति के बाद कोई सुधार विवरण नहीं दिया जाएगा जिसमें उप-धारा (3) में निर्दिष्ट विवरण दिया जाना आवश्यक है।उपरोक्तको ध्यान में रखते हुए, वित्तीय वर्ष 2007-08 से 2018-19 से संबंधित सुधार विवरण केवल 31 मार्च 2025 तक स्वीकार किए जाएंगे। कटौतीकर्ता/कलेक्टर और अन्य हितधारक कृपया इस पर ध्यान दें।
            </span>
            </b>
            </div>
            </div>
            {/* </div> */}
        </section>
    );
}

export default AlertCmponent;